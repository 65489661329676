<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0 title-style" v-if="showTitle">Bull Test History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Bull_Test_History_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': disabled,
          }"
          :disabled="disabled"
          @click="deleteBull(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.scrotalCircumference="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'bullTests',
              item.id,
              'scrotalCircumference',
              item.scrotalCircumference,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.scrotalCircumference"
        >
        </v-text-field>
      </template>
      <template #item.motility="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'bullTests',
              item.id,
              'motility',
              item.motility,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.motility"
        >
        </v-text-field>
      </template>
      <template #item.percentNormal="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'bullTests',
              item.id,
              'percentNormal',
              item.percentNormal,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.percentNormal"
        >
        </v-text-field>
      </template>
      <template #item.headMidPieceDefects="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'bullTests',
              item.id,
              'headMidPieceDefects',
              item.headMidPieceDefects,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.headMidPieceDefects"
        >
        </v-text-field>
      </template>
      <template #item.detachedHeadsTailDefects="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'bullTests',
              item.id,
              'detachedHeadsTailDefects',
              item.detachedHeadsTailDefects,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.detachedHeadsTailDefects"
        >
        </v-text-field>
      </template>
      <template #item.testCheckTime="{ item }">
        {{ $utils.renderValueAs(item.testCheckTime, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "BullTestHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("actions"),
          value: "actions",
        },
        {
          text: this.getTranslation("date"),
          value: "testCheckTime",
        },
        { text: this.getTranslation("test"), value: "test" },
        { text: this.getTranslation("result"), value: "result" },
        {
          text: this.getTranslation("Scrotal Circumference"),
          value: "scrotalCircumference",
        },
        {
          text: this.getTranslation("Motility"),
          value: "motility",
          width: 100,
        },
        {
          text: this.getTranslation("% Normal"),
          value: "percentNormal",
          width: 120,
        },
        {
          text: this.getTranslation("Head/Midpiece Defects"),
          value: "headMidPieceDefects",
          width: 150,
        },
        {
          text: this.getTranslation("Detached Heads/Tail Defects"),
          value: "detachedHeadsTailDefects",
          width: 150,
        },
        {
          text: this.getTranslation("testedBy"),
          value: "testedBy",
          width: 120,
        },
        { text: this.getTranslation("testMethod"), value: "testMethod" },
        { text: this.getTranslation("comment"), value: "comment" },
        {
          text: this.getTranslation("createdOn"),
          value: "createdOn",
          width: 120,
        },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    try {
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.getBullTestHistory();
    },
    getBullTestHistory: function () {
      this.table.loading = true;
      return this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.bullTestHistory || [];
        })
        .catch((e) => {
          console.log(e);
          throw e;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    deleteBull: async function (item) {
      try {
        await this.animal.deleteRecord("bullTests", item.id);
        this.getBullTestHistory();
        this.$emit("data-editted");
        this.$notify({
          group: "forms",
          text: "Bull deleted",
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.title-style {
  font-weight: 600;
  font-size: 22px;
}
</style>
