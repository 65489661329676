<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Group History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Group_History_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': animal.disallowChanges(),
          }"
          :disabled="animal.disallowChanges()"
          @click="deleteGroupNumber(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.groupNumber="{ item }">
        <v-text-field
          :disabled="animal.disallowChanges()"
          :value="item.groupNumber"
          @change="saveGroupNumber($event, item)"
          class="custom-field"
          dense
          hide-details
          outlined
        >
        </v-text-field>
      </template>
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "GroupHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      fertilizations: [],
      herdMeta: null,
      pouches: null,
      pregChecks: [],
      table: {
        data: [],
        freezedData: [],
        loading: true,
      },
      weights: [],
    };
  },
  computed: {
    tableHeaders: function () {
      return [
        { text: this.getTranslation("actions"), value: "actions" },
        { text: this.getTranslation("Group Number"), value: "groupNumber" },
        { text: this.getTranslation("reason"), value: "reason" },
        { text: this.getTranslation("timeRecorded"), value: "timeRecorded" },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    try {
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.getGroupHistory();
      this.fertilizations = this.animal.doc.derived.nestedTables.fertilizations;
      this.pregChecks = this.animal.doc.derived.nestedTables.pregChecks;
      this.weights = this.animal.doc.derived.nestedTables.weights;
    },
    getGroupHistory: function () {
      this.table.loading = true;
      this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.groupNumbers || [];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    saveGroupNumber: async function (groupNumber, item) {
      const promises = [];
      if (!item.groupNumber || item.groupNumber.length == 0) {
        this.$notify({
          group: "forms",
          text: " Group Number is empty.",
          title: "Error",
          type: "error",
        });
        return;
      }

      promises.push(
        this.animal.modify(
          "movements",
          item.id,
          "groupNumber",
          groupNumber,
          true,
          true
        )
      );
      const pregsAffected = this.pregChecks.filter(
        (preg) => preg.groupNumber === item.groupNumber
      );

      const fertilizationsAffected = this.fertilizations.filter(
        (fert) => fert.groupNumber === item.groupNumber
      );

      if (pregsAffected.length) {
        pregsAffected.forEach((preg) =>
          promises.push(
            this.animal.modify(
              "pregChecks",
              preg.id,
              "groupNumber",
              groupNumber,
              true,
              true
            )
          )
        );
      }

      if (fertilizationsAffected.length) {
        fertilizationsAffected.forEach((fert) =>
          promises.push(
            this.animal.modify(
              "fertilizations",
              fert.id,
              "groupNumber",
              groupNumber,
              true,
              true
            )
          )
        );
      }

      try {
        if (promises.length)
          Promise.all(promises).then(() => this.animal.save());
      } catch (error) {
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
    deleteGroupNumber: async function (item) {
      try {
        const promises = [];
        const pregsAffected = this.pregChecks.filter(
          (preg) => preg.groupNumber === item.groupNumber
        );
        const fertilizationsAffected = this.fertilizations.filter(
          (fert) => fert.groupNumber === item.groupNumber
        );
        pregsAffected.forEach((preg) =>
          promises.push(
            this.animal.modify(
              "pregChecks",
              preg.id,
              "groupNumber",
              null,
              true,
              true
            )
          )
        );

        if (fertilizationsAffected.length) {
          fertilizationsAffected.forEach((fert) =>
            promises.push(
              this.animal.modify(
                "fertilizations",
                fert.id,
                "groupNumber",
                null,
                true,
                true
              )
            )
          );
        }

        promises.push(
          this.animal.modify("movements", item.id, "deleted", true, true, true)
        );
        if (promises.length)
          Promise.all(promises).then(() => this.animal.save());
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.disable-cursor {
  cursor: default;
}
</style>
