<template>
  <div>
    <h4 class="mb-4" v-if="showTitle">Weight History</h4>
    <div class="d-flex mb-2" v-if="showExtraInformation">
      <div
        class="d-flex flex-wrap justify-center align-center"
        style="max-width: 230px"
        v-if="table.data[0] && table.data[0].timeRecorded"
      >
        <p
          :class="[
            'subtitle-weight',
            'mb-0',
            $vuetify.theme.dark ? 'white--text' : '',
          ]"
        >
          Last Recorded Weight
          {{ $utils.renderValueAs(table.data[0].timeRecorded, "date") }}
        </p>
        <p
          :class="[
            'weight-value',
            'mb-0',
            $vuetify.theme.dark ? 'white--text' : '',
          ]"
        >
          {{ table.data[0].weight }} lbs.
        </p>
      </div>
      <div
        class="d-flex flex-wrap justify-center align-center"
        style="max-width: 230px"
        v-if="table.data[0] && table.data[0].timeRecorded"
      >
        <p
          :class="[
            'subtitle-weight',
            'mb-0',
            $vuetify.theme.dark ? 'white--text' : '',
          ]"
        >
          Average Daily Weight gain
        </p>
        <p
          :class="[
            'weight-value',
            'mb-0',
            $vuetify.theme.dark ? 'white--text' : '',
          ]"
        >
          {{ animal ? animal.calculateAverageDailyWeightGain() : 0 }}
          lbs
        </p>
      </div>
    </div>
    <export-button
      :disabled="table.data.length == 0"
      :filename="`Weight_History_${animalID}-${$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      )}`"
      :headers="tableHeaders"
      :items="table.data"
      buttonFormat="icon"
      class="mb-2 mr-2"
    />
    <v-btn
      :disabled="table.data.length == 0 || disabled"
      class="pa-0"
      color="success"
      max-width="40"
      min-width="40"
    >
      <v-icon @click="openCreateDialog" class="white--text">mdi-plus</v-icon>
    </v-btn>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': disabled,
          }"
          :disabled="disabled || disallowChanges(item)"
          @click="deleteWeight(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.weight="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="modifyWeight(item)"
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.weight"
        >
        </v-text-field>
      </template>
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
      <template #item.lastUpdated="{ item }">
        {{ $utils.renderValueAs(item.lastUpdated, "datetime", true) }}
      </template>
    </v-data-table>
    <div v-if="showExtraInformation">
      <v-spacer style="height: 40px"></v-spacer>
      <p
        v-if="table.data[0] && table.data[0].lastUpdated"
        style="color: #989089"
      >
        Last Updated:
        {{ $utils.renderValueAs(table.data[0].lastUpdated, "datetime", true) }}
      </p>
    </div>
    <v-dialog
      scrollable
      max-width="400px"
      transition="dialog-transition"
      v-model="dialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4 class="mb-0">New Weight</h4>
          <v-icon @click="dialog.show = false" class="buttons">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <v-text-field
            :label="getTranslation('weight')"
            class="custom-field mb-3"
            clearable
            dense
            hide-details
            outlined
            v-model="dialog.form.weight"
          />
          <v-menu
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="dialog.showCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :label="getTranslation('dateRecorded')"
                append-icon="mdi-calendar"
                class="mb-3 custom-field"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                v-model="dialog.form.date"
                v-on:click:append="dialog.showCalendar = true"
                v-on="on"
              />
            </template>
            <v-date-picker
              @input="dialog.showCalendar = false"
              v-model="dialog.form.date"
            />
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
            v-model="dialog.showTimepicker"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :label="getTranslation('time')"
                append-icon="mdi-clock-time-four"
                class="custom-field"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                v-model="dialog.form.time"
                v-on:click:append="dialog.showTimepicker = true"
                v-on="on"
              />
            </template>
            <v-time-picker v-model="dialog.form.time" />
          </v-menu>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="disabled || !dialog.form.weight"
            @click="createNewWeight"
            color="success"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "WeightHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showExtraInformation: {
      default: false,
      type: Boolean,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      dialog: {
        form: {
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 10),
          source: null,
          time: moment().format("HH:mm"),
          weight: null,
        },
        show: false,
        showCalendar: false,
        showTimepicker: false,
      },
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    datetimeValue: function () {
      const form = this.dialog.form;
      return form.date && form.time
        ? moment(`${form.date} ${form.time}`).toISOString()
        : new Date().toISOString();
    },
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("actions"),
          value: "actions",
        },
        { text: this.getTranslation("Source"), value: "source" },
        {
          text: this.getTranslation("weight"),
          value: "weight",
        },
        { text: this.getTranslation("adg2"), value: "adg2" },
        { text: this.getTranslation("ADG Total"), value: "adgTotal" },
        { text: this.getTranslation("comment"), value: "comment" },
        {
          text: this.getTranslation("daysSinceLastWeight"),
          value: "daysSinceLastWeight",
        },
        {
          text: this.getTranslation("timeRecorded"),
          value: "timeRecorded",
        },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
        { text: this.getTranslation("Last Updated"), value: "lastUpdated" },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    try {
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    createNewWeight: function () {
      this.modifyWeight(this.dialog.form);
      this.dialog.show = false;
    },
    disallowChanges: function (item) {
      const { mainTable, singular } = this.singularAndPlural(item);
      return mainTable === "salesPurchases";
    },
    init: async function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.getWeightHistory();
    },
    getWeightHistory: function () {
      this.table.loading = true;
      return this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.weightHistory || [];
        })
        .catch((e) => {
          console.log(e);
          throw e;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    deleteWeight: async function (item) {
      const { mainTable, singular } = this.singularAndPlural(item);
      if (item.source === "birth") {
        // For birth weights, we insert a null weight instead of deleting the previous
        item.weight = null;
        return this.modifyWeight(item);
      }

      try {
        await this.animal.deleteRecord(mainTable, item.id);
        this.$emit("data-editted");
        this.getWeightHistory();
        this.$notify({
          group: "forms",
          text: "Weight deleted",
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
    modifyWeight: function (item) {
      const { mainTable, singular } = this.singularAndPlural(item);

      return this.animal.modify(
        mainTable,
        item.id,
        singular,
        item.weight,
        true,
        false,
        {
          timeRecorded: this.datetimeValue,
          userId: this.$userID,
        }
      );
    },
    openCreateDialog: function () {
      this.dialog = this.$options.data().dialog;
      this.dialog.show = true;
    },
    singularAndPlural: function (item) {
      let mainTable = "weights";
      let singular = "weight";

      switch (item.source) {
        case "birth":
          mainTable = "birthWeights";
          singular = "birthWeight";
          break;
        case "income":
        case "purchase":
        case "sale":
          mainTable = "salesPurchases";
          singular = "weight";
          break;
        case "preg":
        case "scale":
        case "wean":
        default:
          break;
      }

      return { mainTable, singular };
    },
  },
};
</script>
<style scoped>
.subtitle-weight {
  color: #573a1e;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
.weight-value {
  color: #3a3633;
  font-size: 30px;
  font-weight: 600;
}
</style>
