<template>
  <div ref="mainComponent">
    <v-card class="pa-0" elevation="4" v-if="cardStyle">
      <v-card-title class="pa-3 pb-0" primary-title v-if="cardTitle">
        {{ cardTitle }}
        <slot name="append-card-title"> </slot>
      </v-card-title>
      <v-card-text class="d-flex pa-3" ref="cardComponent">
        <div class="d-flex mr-2">
          <div class="d-flex align-center">
            <v-tooltip
              right
              v-if="!hideTooltip && !quickSearchMode && filterMessage.length"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="align-center d-inline-flex grey justify-center lighten-4 mr-1 rounded-circle"
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                {{ filterMessage }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div class="w-100" ref="searchComponent">
      <div class="d-flex mr-2">
        <div class="d-flex align-center">
          <v-tooltip
            right
            v-if="
              !hideTooltip &&
              !quickSearchMode &&
              !cardStyle &&
              !cardTitle &&
              filterMessage.length
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex grey justify-center lighten-4 mr-1 rounded-circle"
                color="grey"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              {{ filterMessage }}
            </span>
          </v-tooltip>
        </div>
      </div>
      <div
        :class="emptyMessageClass"
        v-if="
          emptyMessage && emptyVisualsErrorLevel && options.visuals.length == 0
        "
      >
        {{ emptyMessage }}
      </div>
      <div v-else>
        <v-progress-linear
          class="mt-2"
          indeterminate
          color="primary"
          v-if="!loaded"
        ></v-progress-linear>
        <v-col v-if="showVisual">
          <v-autocomplete
            :append-icon="
              multipleResults.resultSelected.length &&
              multipleResults.resultSelected[0].eid === search.eid
                ? 'mdi-cellphone-link'
                : ''
            "
            :dense="dense"
            :disabled="!loaded"
            :items="options.eids"
            @input="runSearch"
            auto-select-first
            clear-icon="mdi-close-circle"
            clearable
            hide-details
            outlined
            prepend-inner-icon="mdi-magnify"
            style="font-size: 1.5rem; flex: 1"
            v-if="showEID"
            v-model="search.eid"
          >
            <template #label>
              <div
                :class="{ darkInputText: $vuetify.theme.dark }"
                style="font-size: 1.5rem"
              >
                {{
                  getTranslation("pregCheckPage.pregnancyTestEntry.findByEID")
                }}
              </div>
            </template>
          </v-autocomplete>
          <slot name="visual">
            <v-combobox
              :dense="dense"
              :disabled="!loaded"
              :error="visualErrorState"
              :items="options.visuals"
              :label="
                getTranslation('pregCheckPage.pregnancyTestEntry.findByTag')
              "
              :style="{ 'font-size': dense ? '1.1rem' : '1.5rem' }"
              @input="runSearch"
              @update:search-input="getSearchVisual"
              auto-select-first
              class="mt-2"
              clear-icon="mdi-close-circle"
              clearable
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              style="flex: 1"
              v-if="showVisual"
              v-model="search.visual"
            >
              <template #label>
                <div
                  :class="{ darkInputText: $vuetify.theme.dark }"
                  :style="{ 'font-size': dense ? '1.1rem' : '1.5rem' }"
                >
                  {{ visualTitle }}
                </div>
              </template>
            </v-combobox>
          </slot>
          <slot name="bottom-section"></slot>
          <div class="d-flex align-center" v-if="!quickSearchMode">
            <v-btn
              :disabled="multipleResults.options.length <= 1"
              @click="displayOptionsIfAvailable(multipleResults.options, false)"
              class="px-1 title"
              color="primary"
              elevation="3"
              small
              style="min-width: 50px"
            >
              {{ multipleResults.options.length }}
              <v-icon color="white">mdi-magnify-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </div>
      <v-row class="ma-0" v-if="loaded && includeTagsSummary">
        <v-col class="pa-1 title" cols="6">
          <strong style="font-size: 1.5rem">Confirm</strong>
          <span
            class="ml-2"
            style="font-size: 1.5rem"
            v-if="search.eid != null && isValidEID(search.eid)"
          >
            {{ search.eid.substr(11) }}
          </span>
        </v-col>
        <v-col cols="1" v-if="!quickSearchMode">
          <v-btn
            :disabled="persistDialog"
            @click="useSelectedAnimal"
            color="primary"
          >
            Select
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="loaded && includeTagsSummary">
        <v-col class="pb-0 pt-lg-0 pt-1 title" cols="6">
          <strong style="font-size: 1.5rem">Confirm</strong>
          <span
            class="ml-2"
            style="font-size: 1.5rem"
            v-if="search.eid != null && isValidEID(search.eid)"
          >
            {{ search.eid.substr(11) }}
          </span>
        </v-col>
        <v-col class="pb-0 pt-lg-0 pt-1 title" cols="6">
          <strong style="font-size: 1.5rem"> Viz </strong>
          <span style="font-size: 1.5rem">
            <span v-if="searchMeta.animalIsNew"> NEW </span>
            <span v-else>
              {{
                searchMeta.animal &&
                searchMeta.animal.doc &&
                searchMeta.animal.doc.derived &&
                (searchMeta.animal.doc.derived.summaries.main.visualTagValues ||
                  searchMeta.animal.doc.derived.summaries.main.EIDtagValues ||
                  searchMeta.animal.doc.derived.summaries.main.tagValues)
              }}
            </span>
          </span>
        </v-col>
      </v-row>
      <div v-if="quickSearchMode">
        <h5 class="mt-2 pl-3">Results: {{ multipleResults.options.length }}</h5>
        <v-data-table
          :headers="tableHeaders"
          :items="multipleResults.options"
          class="px-3 dashboard-data-table"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template #item.tagValues="{ item }">
            <router-link
              :to="{
                name: 'AnimalDetails',
                query: { id: item.guid },
              }"
              class="text-h6 text-none"
              target="_blank"
            >
              {{ item.tags }}
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog
      v-model="multipleResults.showDialog"
      max-width="500px"
      scrollable
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>{{ getTranslation("Multiple Results") }}</h4>
          <v-icon class="buttons" @click="multipleResults.showDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text style="height: 300px">
          <h5 class="mt-2 pl-3">
            Results: {{ multipleResults.options.length }}
          </h5>
          <v-data-table
            :headers="tableHeaders"
            :items="multipleResults.options"
            class="px-3 dashboard-data-table"
            hide-default-footer
            item-key="guid"
            mobile-breakpoint="0"
            show-select
            single-select
            v-model="multipleResults.resultSelected"
          >
            <template #item.tagValues="{ item }">
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: item.guid },
                }"
                class="text-h6 text-none"
                target="_blank"
              >
                {{ item.tags }}
              </router-link>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="!multipleResults.resultSelected.length"
            @click="useSelectedAnimal"
            color="success"
          >
            Use selected animal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "SearchAnimal",
  mixins: [TranslationMixin],
  props: {
    allowedGender: {
      default: () => null,
      type: String,
    },
    allowedStatuses: {
      required: true,
      type: Array,
    },
    cardStyle: {
      default: false,
      type: Boolean,
    },
    cardTitle: {
      default: null,
      type: String,
    },
    daysThresholdMax: {
      default: null,
      type: Number,
    },
    daysThresholdMin: {
      default: null,
      type: Number,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    hideTooltip: {
      default: false,
      type: Boolean,
    },
    isDraggable: {
      default: false,
      type: Boolean,
    },
    emptyMessage: {
      type: String,
    },
    emptyVisualsErrorLevel: {
      default: "warning",
      type: String,
    },
    includeTagsSummary: {
      default: false,
      type: Boolean,
    },
    quickSearchMode: {
      default: false,
      type: Boolean,
    },
    resetFields: {
      default: false,
      type: Boolean,
    },
    showEID: {
      default: true,
      type: Boolean,
    },
    showVisual: {
      default: true,
      type: Boolean,
    },
    streamEidOptions: {
      type: Function,
    },
    streamVisualOptions: {
      type: Function,
    },
    visualErrorState: {
      type: Boolean,
    },
    visualTitle: {
      default: "Visual",
      type: String,
    },
  },
  data() {
    return {
      herdConnect: {
        results: {
          eid: null,
          visual: null,
        },
      },
      herdMeta: null,
      IDOptionsAreLoaded: null,
      loaded: false,
      multipleResults: {
        options: [],
        resultSelected: [],
        showDialog: false,
      },
      options: {
        eids: [],
        eidToAnimalShortSummaries: {},
        visuals: [],
        visualToAnimalShortSummaries: {},
      },
      pouches: null,
      search: {
        eid: "",
        visual: "",
      },
      searchMeta: {
        active: false,
        animal: null,
        animalIsNew: true,
      },
    };
  },
  beforeDestroy: function () {
    // When these lines are active, then we have a bug when a user navigates between 2 pages that both use this component
    // FIXME: Find a way to fix this (maybe not make the component a global)
    // EventBus.$tabEvent.off("consumed");
    // EventBus.$off("eid-from-herd-connect");
    // EventBus.$off("visual-from-herd-connect");
  },
  created: function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;

    this.getIDoptions();

    EventBus.$tabEvent.on("consumed", ({ type, value }) => {
      if (type === "eid" && this.search.eid === value) this.search.eid = null;
      if (type === "visual" && this.search.visual === value)
        this.search.visual = null;

      this.getIDoptions();
    });

    EventBus.$on("eid-from-herd-connect", async (newEID) => {
      if (!this.loaded) await this.IDOptionsAreLoaded;

      if (!this.options.eids.some((eid) => eid === newEID)) {
        EventBus.$emit("eid-not-pre-existing", newEID);
        return;
      } else {
        EventBus.$emit("eid-pre-existing", newEID);
      }

      if (this.search.eid === newEID) return;
      this.search.eid = newEID;
      this.runSearch();
    });
    EventBus.$on("visual-from-herd-connect", async (newVisual) => {
      if (!this.loaded) await this.IDOptionsAreLoaded;

      const found = this.options.visuals.find(
        (visual) =>
          (visual || "").toLocaleLowerCase() ===
          (newVisual || "").toLocaleLowerCase()
      );
      if (!found) {
        EventBus.$emit("visual-not-pre-existing", newVisual);
        return;
      } else {
        EventBus.$emit("visual-pre-existing", newVisual);
      }

      if (this.search.visual === newVisual) return;
      // Match case that is in the search
      this.search.visual = found || newVisual;
      this.runSearch();
    });
  },
  mounted: function () {
    const card = this.$refs.cardComponent;
    const search = this.$refs.searchComponent;
    if (this.cardStyle) card.appendChild(search);
  },
  computed: {
    emptyMessageClass: function () {
      return `ma-0 mb-3 btn btn-${this.emptyVisualsErrorLevel}`;
    },
    filterMessage: function () {
      const messages = [];

      if (this.allowedStatuses.length) {
        messages.push(`Status: ${this.allowedStatuses.sort().join(", ")}`);
      }
      if (this.allowedGender) {
        messages.push(`Gender: ${this.allowedGender}`);
      }

      return messages.join(" AND ");
    },
    persistDialog: function () {
      return this.multipleResults.resultSelected.length === 0;
    },
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("calfWeanPage.summary.tagValues"),
          value: "tagValues",
        },
      ];
    },
  },
  watch: {
    isDraggable: function () {
      const mainComponent = this.$refs.mainComponent;
      if (this.isDraggable) {
        mainComponent.classList.add("draggable");
      } else {
        mainComponent.classList.remove("draggable");
      }
    },
    resetFields: function () {
      EventBus.$tabEvent.emit("consumed", {
        type: "eid",
        value: this.search.eid,
      });
      EventBus.$tabEvent.emit("consumed", {
        type: "visual",
        value: this.search.visual,
      });

      this.resetSearch();
    },
    "searchMeta.animal": function (animal) {
      this.searchMeta.animalIsNew = !animal || animal.animalIsNew;
      this.$emit("search-result", {
        animal,
        visual: this.search.visual,
        eid: this.search.eid,
      });
    },
    "multipleResults.options": function (results) {
      this.searchMeta.animal = this.multipleResults.resultSelected = [];
      this.displayOptionsIfAvailable(results, true);
    },
  },
  methods: {
    resetSearch: function () {
      this.search.eid = this.search.visual = null;
      this.runSearch();
    },
    getSearchVisual: function (visual) {
      this.$emit("current-visual", visual);
    },
    isValidEID: function (val) {
      return (
        (/^$|^\d{15}$/.test(
          (val == null ? "" : val + "").replace(/-/g, "").replace(/ /g, "")
        ) || "Invalid ID") != "Invalid ID"
      );
    },
    displayOptionsIfAvailable: function (results, displayNofication) {
      this.multipleResults.showDialog = false;
      if (results.length === 0) {
        this.searchMeta.animal = null;
      } else if (results.length === 1) {
        const doc = results[0].doc;
        this.searchMeta.animal = new Animal(doc._id, this.herdMeta, doc, null);
      } else if (results.length > 1 && !this.quickSearchMode) {
        this.multipleResults.showDialog = true;

        if (!displayNofication) return;

        this.$notify({
          duration: 10000,
          group: "forms",
          text: "There are more results available. Select the correct animal.",
          title: "Multiple Results",
          type: "info",
        });
      }
    },
    forceUseNewAnimal: function () {
      this.searchMeta.animal = null;
      this.multipleResults.showDialog = false;
    },
    getIDoptions: function () {
      this.IDOptionsAreLoaded = new Promise(async (resolve, _reject) => {
        const options =
          await this.$herdMeta.pouches.organization.tagToAnimalShortSummaryBeyondThresholdAndMatchingStatuses(
            this.allowedGender,
            this.allowedStatuses.concat(["onFeed"]),
            [],
            this.daysThresholdMax ? false : this.daysThresholdMin ? true : null,
            false,
            this.daysThresholdMax,
            this.$superAdmin
          );

        this.options.eidToAnimalShortSummaries = options.eid;
        this.options.eids = Object.keys(options.eid).sort();
        this.options.visualToAnimalShortSummaries = options.visual;
        this.options.visuals = Object.keys(options.visual).sort();

        if (this.streamEidOptions) this.streamEidOptions(this.options.eids);

        if (this.streamVisualOptions)
          this.streamVisualOptions(this.options.visuals);

        resolve();

        // If we have search parameters, we should update the result set
        this.runSearch();
      });

      this.IDOptionsAreLoaded.catch(console.error).then(() => {
        this.loaded = true;
      });
    },
    runSearch: async function () {
      this.searchMeta.active = true;
      return await this.searchAnimal(this.search.eid, this.search.visual)
        .then(() => {
          this.searchMeta.active = false;
        })
        .catch(() => {
          this.searchMeta.active = false;
        });
    },
    searchAnimal: function (eid, visual) {
      return new Promise(async (resolve, reject) => {
        const matches = [
          ...new Set([
            ...(this.options.eidToAnimalShortSummaries[eid] || []).map(
              ({ animalId }) => animalId
            ),
            ...(this.options.visualToAnimalShortSummaries[visual] || []).map(
              ({ animalId }) => animalId
            ),
          ]),
        ];

        this.searchAnimal.animal =
          matches.length > 1
            ? await this.pouches.organization.get(matches[0])
            : HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);

        if (matches.length === 0) {
          this.multipleResults.options = [];
        } else {
          const docs = matches.map(
            async (id) => await this.pouches.organization.get(id)
          );

          this.multipleResults.options = await Promise.all(docs)
            .then((docs) =>
              docs
                .filter((doc) => doc !== null)
                .map((doc) => {
                  doc.derived ||= {
                    summaries: Animal.defaultSummaries(doc._id),
                  };
                  return doc;
                })
                .map((doc) => ({
                  doc: doc,
                  tagValues:
                    (doc &&
                      doc.derived &&
                      doc.derived.summaries.main.tagValues) ||
                    "",
                }))
                .sort((a, b) => a.tagValues.localeCompare(b.tagValues))
                .map((result, idx) => ({
                  doc: result.doc,
                  guid: result.doc._id,
                  tags: result.tagValues,
                  tagValues: `${idx + 1} - ${result.tagValues}`,
                }))
            )
            .catch((e) => {
              console.error(e);
              reject(e);
            });
        }

        resolve();
      });
    },
    useSelectedAnimal: function () {
      this.searchMeta.animal = this.multipleResults.resultSelected[0]
        ? new Animal(
            this.multipleResults.resultSelected[0].doc._id,
            this.herdMeta,
            this.multipleResults.resultSelected[0].doc,
            this.$userID
          )
        : null;
      this.multipleResults.showDialog = false;
    },
  },
};
</script>
