<template>
  <div class="my-2">
    <div class="g-row">
      <div class="g-col g-col-2 mobile-row">
        <v-progress-circular indeterminate size="50" v-if="loading" />
        <div class="g-row" v-else>
          <div class="g-col mobile-row">
            <!-- Sex -->
            <div class="my-2">
              <p class="subtitle-style">{{ getTranslation("sex") }}</p>
              <v-select
                :disabled="disabled"
                :items="options.sexes"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @input="
                  animal.modify(
                    'sex',
                    null,
                    'sexId',
                    details.sex.sexId || details.sex.id,
                    false,
                    !!details.sex.gender,
                    {
                      gender: details.sex.gender,
                      sex:
                        details.sex.sexId || details.sex.id
                          ? animal.herdMeta.sexesById[
                              details.sex.sexId || details.sex.id
                            ].sex
                          : null,
                    }
                  );
                  if (!details.sex.gender) return;
                  animal.modify(
                    'genders',
                    null,
                    'gender',
                    details.sex.gender,
                    false,
                    false
                  );
                "
                dense
                hide-details
                item-text="sex"
                item-value="sex"
                return-object
                v-model="details.sex"
              >
              </v-select>
              <p
                @click="sexDialog.show = true"
                class="mt-3 mb-0 bottom-subtitle"
              >
                {{ getLabelTranslation("History") }}
              </p>
            </div>

            <!-- Color -->
            <div class="my-2">
              <p class="subtitle-style">{{ getTranslation("color") }}</p>
              <v-select
                :disabled="disabled"
                :items="options.colors"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @input="
                  animal.modify(
                    'color',
                    null,
                    'colorId',
                    details.color.colorId || details.color.id,
                    false,
                    false,
                    {
                      color:
                        details.color.colorId || details.color.id
                          ? animal.herdMeta.colorsById[
                              details.color.colorId || details.color.id
                            ].color
                          : null,
                    }
                  )
                "
                dense
                hide-details
                item-text="color"
                item-value="color"
                return-object
                v-model="details.color"
              >
              </v-select>
              <p
                @click="colorDialog.show = true"
                class="mt-3 mb-0 bottom-subtitle"
              >
                {{ getLabelTranslation("History") }}
              </p>
            </div>

            <!-- TSU -->
            <div class="my-2">
              <p class="subtitle-style">{{ getTranslation("Dna Number") }}</p>
              <v-text-field
                :disabled="disabled"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @change="
                  animal.modify(
                    'dnaNumbers',
                    null,
                    'dnaNumber',
                    details.tsu,
                    false,
                    false,
                    {
                      userId: $userID,
                    }
                  )
                "
                dense
                hide-details
                v-model="details.tsu"
              >
              </v-text-field>
              <p
                @click="tsuDialog.show = true"
                class="mt-3 mb-0 bottom-subtitle"
              >
                {{ getLabelTranslation("History") }}
              </p>
            </div>
          </div>
          <div class="g-col">
            <!-- Group -->
            <div class="my-2">
              <p class="subtitle-style">{{ getTranslation("Group") }}</p>
              <v-text-field
                :disabled="disabled"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @change="
                  details.group == ''
                    ? $notify({
                        group: 'forms',
                        text: getTranslation('useGroupHistoryToDelete'),
                        title: 'Error',
                        type: 'error',
                      })
                    : animal.modify(
                        'movements',
                        null,
                        'groupNumber',
                        details.group,
                        false,
                        false,
                        {
                          userId: $userID,
                        }
                      )
                "
                dense
                hide-details
                v-model="details.group"
              >
              </v-text-field>
              <p
                @click="groupDialog.show = true"
                class="mt-3 mb-0 bottom-subtitle"
              >
                {{ getLabelTranslation("History") }}
              </p>
            </div>

            <!-- Lot Number -->
            <div class="my-2">
              <p class="subtitle-style">{{ getTranslation("lotNumber") }}</p>
              <v-text-field
                :disabled="disabled"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @change="
                  animal.modify(
                    'ids',
                    null,
                    'tagValue',
                    details.lotNumber,
                    false,
                    false,
                    {
                      type: 'tagLot',
                      userId: $userID,
                    }
                  )
                "
                dense
                hide-details
                v-model="details.lotNumber"
              >
              </v-text-field>
            </div>

            <!-- Herd -->
            <div class="my-2">
              <p class="subtitle-style">{{ getTranslation("Herd") }}</p>
              <v-select
                :disabled="disabled"
                :items="options.herds"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @input="
                  animal.modify(
                    'herds',
                    null,
                    'herdId',
                    details.herd.herdId || details.herd.id,
                    false,
                    false,
                    {
                      herd:
                        details.herd.herdId || details.herd.id
                          ? (animal.herdMeta.herdsById[
                              details.herd.herdId || details.herd.id
                            ] &&
                              animal.herdMeta.herdsById[
                                details.herd.herdId || details.herd.id
                              ].herd) ||
                            details.herd.name
                          : null,
                      userId: $userID,
                    }
                  )
                "
                dense
                hide-details
                item-text="name"
                item-value="name"
                return-object
                v-model="details.herd"
              >
              </v-select>
              <p
                @click="herdDialog.show = true"
                class="mt-3 mb-0 bottom-subtitle"
              >
                {{ getLabelTranslation("History") }}
              </p>
            </div>

            <!-- Location -->
            <div class="my-2">
              <p class="subtitle-style">
                {{ getLabelTranslation("pastureSlashPen") }}
              </p>
              <v-select
                :disabled="disabled"
                :items="options.locations"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                :outlined="!disabled"
                :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
                :solo="disabled"
                @input="
                  animal.modify(
                    'movements',
                    null,
                    'locationId',
                    details.location.id,
                    false,
                    false,
                    {
                      location: details.location.name,
                      userId: $userID,
                    }
                  )
                "
                dense
                hide-details
                item-text="name"
                return-object
                v-model="details.location"
              >
              </v-select>
              <p
                @click="sightingDialog.show = true"
                class="mt-3 mb-0 bottom-subtitle"
              >
                {{ getLabelTranslation("History") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="g-col g-col-1 tablet-row">
        <animal-location :animalID="animalID" />
      </div>
    </div>

    <div v-if="showExtraInformation">
      <v-spacer style="height: 40px"></v-spacer>
      <p v-if="details && details.lastUpdated" style="color: #989089">
        Last Updated:
        {{ $utils.renderValueAs(details.lastUpdated, "datetime", true) }}
      </p>
    </div>
    <v-dialog
      max-width="1000px"
      scrollable
      transition="dialog-transition"
      v-model="sexDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Sex History</h4>
          <v-icon class="buttons" @click="sexDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <sex-history-table :animalID="animalID" @data-editted="init" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="1000px"
      scrollable
      transition="dialog-transition"
      v-model="tsuDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>TSU History</h4>
          <v-icon class="buttons" @click="tsuDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <tsu-history-table :animalID="animalID" @data-editted="init" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="1000px"
      scrollable
      transition="dialog-transition"
      v-model="colorDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Color History</h4>
          <v-icon class="buttons" @click="colorDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <color-history-table :animalID="animalID" @data-editted="init" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="900px"
      scrollable
      transition="dialog-transition"
      v-model="sightingDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Sighting History</h4>
          <v-icon class="buttons" @click="sightingDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <sighting-history-table :animalID="animalID" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="1000px"
      scrollable
      transition="dialog-transition"
      v-model="groupDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Group History</h4>
          <v-icon class="buttons" @click="groupDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <group-history-table :animalID="animalID" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="1000px"
      scrollable
      transition="dialog-transition"
      v-model="herdDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Herd History</h4>
          <v-icon class="buttons" @click="herdDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <herd-history-table :animalID="animalID" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ColorHistoryTable from "./colorHistory.vue";
import GroupHistoryTable from "./groupHistory.vue";
import HerdHistoryTable from "./herdHistory.vue";
import SexHistoryTable from "./sexHistory.vue";
import SightingHistoryTable from "./sightingHistory.vue";
import TranslationMixin from "../../mixins/Translations";
import TsuHistoryTable from "./tsuHistory.vue";
export default {
  name: "GeneralDetails",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showExtraInformation: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    ColorHistoryTable,
    GroupHistoryTable,
    HerdHistoryTable,
    SexHistoryTable,
    SightingHistoryTable,
    TsuHistoryTable,
  },
  data: () => ({
    animal: null,
    broadcast: null,
    colorDialog: {
      show: false,
    },
    details: {},
    groupDialog: { show: false },
    herdDialog: { show: false },
    herdMeta: null,
    loading: false,
    pouches: null,
    options: {},
    sexDialog: {
      show: false,
    },
    sightingDialog: {
      show: false,
    },
    tsuDialog: {
      show: false,
    },
  }),
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = (e) => {
      this.init();
    };
    this.loading = true;
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    try {
      await this.herdMeta.promiseLoaded();
      await this.init();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  computed: {
    animalDerivationIsReady: function () {
      return (
        this.animal &&
        this.animal.derived &&
        this.animal.derived.summaries &&
        // gender is a good sentinel to detect whether full summaries exist
        this.animal.derived.summaries.main.gender
      );
    },
    animalHasTransfers: function () {
      if (!this.animalDerivationIsReady) {
        return false;
      }
      return (this.animal.doc.transfers || []).some(({ deleted }) => !deleted);
    },
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
  },
  methods: {
    init: async function () {
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      await this.getSelectOptions();
      this.getGeneralDetails();
    },
    getSelectOptions: async function () {
      this.options.colors = [
        { id: "", color: "" },
        ...((await this.herdMeta.getMetaColorsAsync(true, true, true)) || []),
      ];
      this.options.herds = [
        { id: "", herd: "", name: "" },
        ...((await this.herdMeta.getMetaHerdsAsync(true, true, true)) || []),
      ];
      this.options.locations =
        (await this.herdMeta.getMetaLocationsAsync(true, true, true)) || [];
      this.options.sexes = [
        { id: "", sex: "" },
        ...((await this.herdMeta.getMetaSexesAsync(true, true, true)) || []),
      ];
    },
    getGeneralDetails: function () {
      const mainSummary = this.animal.doc.derived.summaries.main;
      const sex = this.animal.doc.derived.characteristics.sex;
      const color = this.animal.doc.derived.characteristics.color;
      const herd = mainSummary.herd;

      const lastLocation = Animal.getLastLocation(
        this.animal.doc.derived.nestedTables.sightings,
        this.animal.doc.movements
      );
      const location = {
        id: lastLocation ? lastLocation.locationId : null,
        name: lastLocation ? lastLocation.location : null,
      };
      if (
        sex.sex &&
        this.options.sexes.some((currentSex) => currentSex.sex !== sex.sex)
      )
        this.options.sexes.splice(1, 0, sex);
      if (
        color.color &&
        this.options.colors.some(
          (currentColor) => currentColor.color !== color.color
        )
      )
        this.options.colors.splice(1, 0, color);

      if (
        herd &&
        this.options.herds.some((currentHerd) => currentHerd.herd !== herd)
      )
        this.options.herds.splice(1, 0, { herd, id: "", name: herd });

      if (
        location.name &&
        this.options.locations.some(
          (currentLocation) => currentLocation.name !== location.name
        )
      )
        this.options.locations.splice(1, 0, location);

      this.details = [
        {
          color: color.color || mainSummary.color,
          group: mainSummary.groupNumber,
          herd: mainSummary.herd,
          lastUpdated: mainSummary.lastUpdated,
          location: location.name,
          lotNumber: mainSummary.tagLot,
          sex: sex.sex || mainSummary.sex,
          tsu: mainSummary.dnaNumber,
        },
      ][0];
    },
  },
};
</script>
<style scoped>
>>> .v-input {
  max-width: 280px;
}
</style>
<style lang="scss" scoped>
.subtitle-style {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px !important;
}

.bottom-subtitle {
  color: #877e76;
  cursor: pointer;
  font-size: 17px;
  font-weight: 300;
  text-decoration: underline;
  width: fit-content;
}
</style>
