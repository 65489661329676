const initialState = () => ({
  darkMode: null,
  drawerMiniVariant: true,
  email: "",
  initialUserID: null,
  locale: null,
  name: "",
  pouchPass: null,
  superAdmin: 0,
  timezone: null,
  token: "",
  type: "",
  userID: null,
  username: "",
});

// state
const state = initialState();

// getters
const getters = {
  getDarkMode: (state) => state.darkMode,
  getInitialUserID: (state) => state.initialUserID,
  getLocale: (state) => state.locale,
  getName: (state) => state.name,
  getPouchPass: (state) => state.pouchPass,
  getSuperAdmin: (state) => state.superAdmin,
  getTimezone: (state) => state.timezone,
  getToken: (state) => state.token,
  getType: (state) => state.type,
  getUserEmail: (state) => state.email,
  getUserID: (state) => state.userID,
  getUsername: (state) => state.username,
  getNameInitials: (_, getters) => {
    const [first, second] = getters.getName.split(" ");
    if (first && second) return `${first[0]}${second[0]}`.toUpperCase();
    return first && first[0].toUpperCase();
  },
};

// actions
const actions = {
  reset: ({ commit }) => {
    commit("RESET");
  },
  setDarkMode: ({ commit }, { darkMode }) => {
    commit("setDarkMode", { darkMode });
  },
  setInitialUserID: ({ commit }, { userID }) => {
    commit("setInitialUserID", { userID });
  },
  setLocale: ({ commit }, { locale }) => {
    commit("setLocale", { locale });
  },
  setName: ({ commit }, { name }) => {
    commit("setName", { name });
  },
  setPouchPass: ({ commit }, { pouchPass }) => {
    commit("setPouchPass", { pouchPass });
  },
  setSuperAdmin: ({ commit }, { superAdmin }) => {
    commit("setSuperAdmin", { superAdmin });
  },
  setTimezone: ({ commit }, { timezone }) => {
    commit("setTimezone", { timezone });
  },
  setToken: ({ commit }, { token }) => {
    commit("setToken", { token });
  },
  setType: ({ commit }, { type }) => {
    commit("setType", { type });
  },
  setUserEmail: ({ commit }, { email }) => {
    commit("setUserEmail", { email });
  },
  setUserID: ({ commit }, { userID }) => {
    commit("setUserID", { userID });
  },
  setDrawerMiniVariant({ commit }, payload) {
    commit("setDrawerMiniVariant", payload);
  },
};

// mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      if (key != "darkMode") state[key] = newState[key];
    });
  },
  setDarkMode(state, { darkMode }) {
    state.darkMode = darkMode;
  },
  setInitialUserID(state, { userID }) {
    state.initialUserID = userID;
  },
  setLocale(state, { locale }) {
    state.locale = locale;
  },
  setName(state, { name }) {
    state.name = name;
  },
  setPouchPass(state, { pouchPass }) {
    state.pouchPass = pouchPass;
  },
  setSuperAdmin(state, { superAdmin }) {
    state.superAdmin = superAdmin;
  },
  setTimezone(state, { timezone }) {
    state.timezone = timezone;
  },
  setToken(state, { token }) {
    state.token = token;
  },
  setType(state, { type }) {
    state.type = type;
  },
  setUserID(state, { userID }) {
    state.userID = userID;
  },
  setUserEmail(state, { email }) {
    state.email = email;
  },
  setDrawerMiniVariant(state, payload) {
    state.drawerMiniVariant = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
