var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0 title-style"},[_vm._v("Bull Test History")]):_vm._e(),_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Bull_Test_History_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deleteBull(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.scrotalCircumference",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'bullTests',
            item.id,
            'scrotalCircumference',
            item.scrotalCircumference,
            true,
            false
          )}},model:{value:(item.scrotalCircumference),callback:function ($$v) {_vm.$set(item, "scrotalCircumference", $$v)},expression:"item.scrotalCircumference"}})]}},{key:"item.motility",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'bullTests',
            item.id,
            'motility',
            item.motility,
            true,
            false
          )}},model:{value:(item.motility),callback:function ($$v) {_vm.$set(item, "motility", $$v)},expression:"item.motility"}})]}},{key:"item.percentNormal",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'bullTests',
            item.id,
            'percentNormal',
            item.percentNormal,
            true,
            false
          )}},model:{value:(item.percentNormal),callback:function ($$v) {_vm.$set(item, "percentNormal", $$v)},expression:"item.percentNormal"}})]}},{key:"item.headMidPieceDefects",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'bullTests',
            item.id,
            'headMidPieceDefects',
            item.headMidPieceDefects,
            true,
            false
          )}},model:{value:(item.headMidPieceDefects),callback:function ($$v) {_vm.$set(item, "headMidPieceDefects", $$v)},expression:"item.headMidPieceDefects"}})]}},{key:"item.detachedHeadsTailDefects",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'bullTests',
            item.id,
            'detachedHeadsTailDefects',
            item.detachedHeadsTailDefects,
            true,
            false
          )}},model:{value:(item.detachedHeadsTailDefects),callback:function ($$v) {_vm.$set(item, "detachedHeadsTailDefects", $$v)},expression:"item.detachedHeadsTailDefects"}})]}},{key:"item.testCheckTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.testCheckTime, "datetime", true))+" ")]}},{key:"item.createdOn",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }